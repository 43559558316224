import jwtDecode from 'jwt-decode';
import { useSnackbar } from 'notistack';
import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { get as getAccessControl } from 'src/slices/accessControl';
import { useDispatch } from 'src/store';
import { User } from 'src/types/user';
import { api, refreshAccessToken } from 'src/utils/api';
import { checkRolesForAccess } from 'src/utils/auth';
import axios from 'src/utils/axios';
import TokenService from 'src/utils/tokenService';
import {SetAuthenticatedUserContext} from 'src/utils/appInsight';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const decoded: any = jwtDecode(token);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatchForReduxStore = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email: string, password: string) => {
    try {
      const { data: { token, id, roles, userName, firstName, lastName, refreshToken }, status } = await api('User/userLogin', 'post', {
        username: email,
        password,
        rememberMe: false,
      });
      if (status === 200) {
        if (checkRolesForAccess(roles)) { // Check roles for access.
          const { data: accessData, status: accessStatus } = await api('accessControls', 'get', {}, { Authorization: `Bearer ${token}` });
          const accessControl = accessData.reduce((acc, access) => {
            const { module, delete: del, update, write, read } = access;
            return { ...acc, [module]: { delete: del, update, write, read } };
          }, {});
          if (accessStatus === 200) {
            TokenService.updateLocalAccessToken(token);
            TokenService.updateLocalRefreshToken(refreshToken);
            dispatchForReduxStore(getAccessControl(accessControl));
            dispatch({
              type: 'LOGIN',
              payload: {
                user: { id, avatar: '', email: '', name: userName, roles, firstName, lastName }
              }
            });
            SetAuthenticatedUserContext(userName);
          } else {
            throw new Error('Not able to fetch permissions for the user.');
          }
        } else {
          enqueueSnackbar('The user do not have permissions to login.', {
            variant: 'warning',
            autoHideDuration: 3000
          });
        }
      } else if (status === 401) {                         // For unauthorized user
        enqueueSnackbar('Failed to log in. Please try again.', {
          variant: 'error',
          autoHideDuration: 3000
        });
      } else {
        enqueueSnackbar('Some error occurred! Please try again.', {
          variant: 'error',
          autoHideDuration: 3000
        });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  const logout = () => {
    TokenService.logOut()
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User; }>('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const refreshToken = TokenService.getLocalRefreshToken();

        if (refreshToken && isValidToken(refreshToken)) {
          // Refresh the access token on init
          await refreshAccessToken();

          const { data: { id, username, email, userRoles, firstName, lastName }, status } = await api('User/me', 'get');

          if (status === 200) {
            if (checkRolesForAccess(userRoles)) { // Check roles for access.
              const { data: accessData, status: accessStatus } = await api('accessControls', 'get');
              const accessControl = accessData.reduce((acc, access) => {
                const { module, delete: del, update, write, read } = access;
                return { ...acc, [module]: { delete: del, update, write, read } };
              }, {});
              if (accessStatus === 200) {
                dispatchForReduxStore(getAccessControl(accessControl));
                dispatch({
                  type: 'INITIALISE',
                  payload: {
                    isAuthenticated: true,
                    user: {
                      id, avatar: '', email, name: username, roles: userRoles, firstName, lastName
                    }
                  }
                });
              } else {
                throw new Error('Not able to fetch permissions for the user.');
              }
            } else {
              enqueueSnackbar('The user do not have permissions to access this application.', {
                variant: 'warning',
                autoHideDuration: 3000
              });
              dispatch({
                type: 'INITIALISE',
                payload: {
                  isAuthenticated: false,
                  user: null
                }
              });
            }
          } else {
            alert("FAILED" + status)
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;