/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import { StyleRules, useTheme } from '@material-ui/styles';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'src/assets/css/simpleKeyboard.css';
import { Theme } from 'src/theme';

export enum Layout {
  Qwerty = 'qwerty',
  Numpad = 'numpad',
  Email = 'email',
  Discount = 'discounts'
}
interface SimpleKeyboardInterface {
  onChange: (input: string) => void;
  // keyboardRef: MutableRefObject<typeof Keyboard>;
  keyboardRef?: string;
  layout?: Layout;
}

const keyboardLayout = {
  qwerty: {
    default: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      '{tab} q w e r t y u i o p [ ] \\',
      "{lock} a s d f g h j k l ; ' {enter}",
      '{shift} z x c v b n m , . / {shift}',
      '.com @ {space}'
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : " {enter}',
      '{shift} Z X C V B N M < > ? {shift}',
      '.com @ {space}'
    ]
  },
  email: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 + - {bksp}',
      'q w e r t y u i o p [ ]',
      '{lock} a s d f g h j k l',
      '{shift} z x c v b n m . {shift}',
      '.com @ @gmail.com @aol.com @yahoo.com {space}'
    ],
    shift: [
      '` ~ ! # $ % ^ & * ( ) _ = {bksp}',
      'Q W E R T Y U I O P { }',
      '{lock} A S D F G H J K L',
      '{shift} Z X C V B N M . {shift}',
      '.com @ @gmail.com @aol.com @yahoo.com {space}'
    ]
  },
  discounts: {
    default: [
      ' 1 2 3 4 5 6 7 8 9 0 {bksp} ',
      ' Q W E R T Y U I O P ',
      ' A S D F G H J K L ',
      ' Z X C V B N M ',
      '{space}'
    ]
  },
  numpad: {
    default: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '+ 0 {bksp}'
    ]
  }
};

const buttons = "` 1 2 3 4 5 6 7 8 9 0 ~ ! @ # $ % ^ & * ( ) < > ? - = {bksp} {tab} q w e r t y u i o p Q W E R T Y U I O P [ ] \\ {lock} a s d f g h j k l A S D F G H J K L ; ' {enter} z x c v b n m Z X C V B N M , . / {shift} .com @aol.com @gmail.com @yahoo.com @ {space} _ + : | \" { } ";

const getStyleJson = (theme: Theme): StyleRules<any> => {

  const { palette: { action: { active }, background: { dark, paper }, text: { primary } }, customs: { keyBoard: { keyShadow } } } = theme;
  return {
    layoutColor: {
      background: `${paper}`,
    },
    btn: {
      '&:active': {
        background: `${active}`,
        color: `${dark}`,
      },
      background: `${dark}`,
      color: `${primary}`,
      borderBottom: `3px solid ${keyShadow}`,
      boxShadow: `0 0 3px -1px ${dark}`,
      height: ({ layout }) => (`${layout !== Layout.Numpad ? '85px' : '107px'} !important`),
      fontSize: ({ layout }) => (`${layout !== Layout.Numpad ? '2rem' : '4rem'}`),
      // transition: "background 100ms ease-out, color 100ms ease-in"
    },
    btnDisabled: {
      pointerEvents: 'none',
      opacity: '0.5',
    },
    btnWidth33: {
      'width': '33.33%!important'
    }
  };
};
const useStyles = makeStyles((theme: Theme) => getStyleJson(theme));

const SimpleKeyboard: FC<SimpleKeyboardInterface> = ({
  onChange,
  keyboardRef,
  layout = Layout.Qwerty
}) => {

  const [layoutName, setLayoutName] = useState('default');
  const classes = useStyles({ layout });
  const theme: Theme = useTheme();
  const keyboard = useRef(null);
  const onKeyPress = useCallback(
    (button: string) => {
      if (button === '{shift}' || button === '{lock}') {
        setLayoutName((prevLayoutName) =>
          prevLayoutName === 'default' ? 'shift' : 'default'
        );
      }
    },
    []
  );
  const { qwerty, numpad, email, discounts } = keyboardLayout;

  const buttonTheme = useMemo(() => {
    return (layout) => {
      if (layout === Layout.Numpad) {
        return [
          { class: classes.btn, buttons },
          {
            class: layout === Layout.Numpad ? classes.btnDisabled : '',
            buttons: '+',
          },
          {
            class: layout === Layout.Numpad ? classes.btnWidth33 : '',
            buttons: '+ 0 {bksp}',
          },
        ];
      } else {
        return [{ class: classes.btn, buttons }];
      }
    };
  }, [classes, buttons]);

  useEffect(() => {
    if (keyboardRef) {
      keyboard?.current?.setInput(keyboardRef);
    }
  }, [keyboardRef]);

  return (
    <Keyboard
      keyboardRef={r => (keyboard.current = r)}
      layoutName={layoutName}
      layout={layout === Layout.Qwerty ? qwerty : layout === Layout.Email ? email : layout === Layout.Discount ? discounts : numpad}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onRender={() => console.log('Keyboard Rendered')}
      physicalKeyboardHighlight={true}
      physicalKeyboardHighlightBgColor={theme.palette.action.active}
      theme={`hg-theme-default custom-keyboard-theme ${classes.layoutColor}`}
      buttonTheme={buttonTheme(layout)}
      display={layout === Layout.Numpad && {
        '{bksp}': '⌫',
        '+': ' '
      }}
    />
  );
};

export default React.memo(SimpleKeyboard);
