import _ from 'lodash';
import type { FC, ReactNode } from 'react';
import React, {
  createContext,
  useEffect,
  useState
} from 'react';
import { Layout } from 'src/components/SimpleKeyboard';
import { THEMES } from 'src/constants';
import { confirmationCompleteTime } from 'src/utils/common';

interface TableSizes {
  [key: string]: number;
}
interface Tables {
  sizes: TableSizes;
}

export interface Settings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  theme?: string;
  stationId?: number;
  pos?: string;
  permanentId?: string;
  completeTime?: number;
  manualStart?: boolean;
  rightDirectionArrow?: boolean;
  facialStart?: boolean;
  barcodeReaderStart?: boolean;
  emailReceiptEnabled?: boolean;
  paymentTerminalLocation?: string;
  discountsEnabled?: boolean;
}

interface KeyboardProps {
  handleChange: (val: string) => void;
  keyboardRef: string;
  layout?: Layout;
  handleSetKeyboardLayout: (val: Layout) => void;
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
  toggleKeyboard: (show: boolean) => void;
  isKeyboardVisible: boolean;
  keyboardProps: KeyboardProps;
}

interface SettingsProviderProps {
  settings?: Settings;
  children?: ReactNode;
}

const defaultSettings: Settings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  completeTime: confirmationCompleteTime.default
};

export const restoreSettings = (): Settings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => { },
  toggleKeyboard: () => { },
  isKeyboardVisible: false,
  keyboardProps: {
    handleChange: () => { },
    keyboardRef: '',
    layout: Layout.Qwerty,
    handleSetKeyboardLayout: () => { }
  }
});

export const SettingsProvider: FC<SettingsProviderProps> = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState<Settings>(settings || defaultSettings);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [keyboardRef, setKeyboardRef] = useState<string>('');
  const [keyboardLayout, setKeyboardLayout] = useState<Layout>(Layout.Qwerty);
  const handleSaveSettings = (update: Settings = {}): void => {
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  const handleKeyboardRefChange = (val: string) => {
    setKeyboardRef(val);
  };

  const handleSetKeyboardLayout = (val: Layout) => {
    setKeyboardLayout(val);
  };

  const toggleKeyboard = (show: boolean): void => {
    setIsKeyboardVisible(show);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
        toggleKeyboard,
        isKeyboardVisible,
        keyboardProps: {
          keyboardRef,
          layout: keyboardLayout,
          handleChange: handleKeyboardRefChange,
          handleSetKeyboardLayout
        }
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
